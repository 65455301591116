import PropTypes from 'prop-types';
import React from 'react';

import { Grid } from 'site-react/components/page';

import styles from './ProductFeature.module.css';

const ProductFeature = ({ children, image, flip }) => (
  <Grid>
    <Grid.Item
      colSpan="6"
      colStart={flip ? '7' : undefined}
      justify="center"
      rowSpan={flip ? '1' : undefined}
      rowStart={flip ? '1' : undefined}
    >
      <div className={styles['ProductFeature-image']}>{image}</div>
    </Grid.Item>
    <Grid.Item
      align="center"
      colSpan="5"
      colStart={flip ? '1' : undefined}
      rowSpan={flip ? '1' : undefined}
      rowStart={flip ? '1' : undefined}
    >
      <div className={styles['ProductFeature-content']}>{children}</div>
    </Grid.Item>
  </Grid>
);

ProductFeature.propTypes = {
  children: PropTypes.node.isRequired,
  flip: PropTypes.bool,
  image: PropTypes.node.isRequired,
};

export default ProductFeature;
