import PropTypes from 'prop-types';
import React from 'react';

import { Heading, Paragraph } from 'site-react/components/typography';
import { Asset, VerticalSpacing } from 'site-react/components/utility';

import styles from './Blurb.module.css';

const Blurb = ({
  content,
  illustration = null,
  textColor = 'white',
  title,
}) => {
  return (
    <div
      className={styles['Blurb']}
      style={{
        '--Blurb-textColor': `var(--color-${textColor})`,
      }}
    >
      <VerticalSpacing size="xl">
        {illustration && (
          <Asset
            alt=""
            height="155px"
            sizingBehaviour="fixed"
            src={illustration}
          />
        )}
      </VerticalSpacing>
      <VerticalSpacing size="md">
        <Heading color={textColor} level="3" type="title3">
          {title}
        </Heading>
      </VerticalSpacing>
      <Paragraph type="content1">{content}</Paragraph>
    </div>
  );
};

Blurb.propTypes = {
  /**
   * Additional detail to add to the title.
   */
  content: PropTypes.string.isRequired,

  /**
   * SVG image to illustrate the blurb.
   */
  illustration: PropTypes.string,

  /**
   * The color of the text to pass to Heading and Paragraph components.
   */
  textColor: PropTypes.oneOf(['neutral900', 'white']),

  /**
   * The main point of what we're detailing in this blurb.
   */
  title: PropTypes.string.isRequired,
};

export default Blurb;
