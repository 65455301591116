import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';

import { UICardLink } from 'site-react/components/page';
import {
  Badges,
  Heading,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import analytics from 'site-react/helpers/Analytics';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';

import styles from './ProductCard.module.css';

const ProductCard = ({
  badgeColor = 'brandsecondary',
  badgeLabel,
  description,
  href = '',
  imageAlt,
  imageSrc,
  linkType = 'a',
  productName,
  target,
}) => {
  return (
    <UICardLink
      boxShadow="resting"
      href={href}
      linkType={linkType}
      name={productName}
      onClick={() => {
        analytics.track(`Popular spaces: ${productName} clicked`);
      }}
      style={{
        border: 'none',
        padding: 0,
      }}
      target={target}
    >
      <div className={styles['ProductCard-innerCard']}>
        <div className={styles['ProductCard-imageWrapper']}>
          <Image
            alt={imageAlt}
            fill
            loader={imgixLoader}
            src={getImgixPathWithParameters(imageSrc, {})}
            style={{
              objectFit: 'cover',
              objectPosition: 'bottom',
            }}
          />
        </div>
        <div className={styles['ProductCard-innerContent']}>
          <Badges.Badge color={badgeColor} isSmall label={badgeLabel} />
          <VerticalSpacing size="sm" />
          <Heading type="title4">{productName}</Heading>
          <Paragraph isMarginless type="content2">
            {description}
          </Paragraph>
          <div className={styles['ProductCard-spacer']} />
          <div className={styles['ProductCard-ctaAlign']}>
            <TextWithIcon
              color="brandPrimary"
              contentType="content2"
              iconName="chevron_right"
              iconPosition="right"
              iconSize="md"
              text={<strong>Search now</strong>}
            />
          </div>
        </div>
      </div>
    </UICardLink>
  );
};

ProductCard.propTypes = {
  /**
   * The color of the decorative badge.
   */
  badgeColor: PropTypes.oneOf(['brandsecondary', 'green-200']),

  /**
   * Text to be displayed inside of decorative badge.
   */
  badgeLabel: PropTypes.string.isRequired,

  /**
   * A body of text to be displayed underneath the product name.
   */

  description: PropTypes.string.isRequired,

  /**
   * Where this link leads. Or, if you're using an app link, the route to link
   * to (ie. the path within Next's `pages` folder for the page you're linking
   * to)
   */
  href: PropTypes.oneOfType([
    PropTypes.string,
    // https://nextjs.org/docs/api-reference/next/link#with-url-object
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
  ]).isRequired,

  /**
   * A description of the image for accessibility
   */
  imageAlt: PropTypes.string.isRequired,

  /**
   * Image to display alongside product details.
   */
  imageSrc: PropTypes.string.isRequired,

  /**
   * The type of the link.:
   * - a for internal links in this project. Gets benefits of performance enhancements
   *   from next/link.
   * - OutboundLink for a link that leads off-site. Requires different logic for
   *   analytics tracking.
   */
  linkType: PropTypes.oneOf(['OutboundLink', 'a']),

  /**
   * The title of the product.
   */
  productName: PropTypes.string.isRequired,

  /**
   * Target attribute. Only applies to <a> elements.
   */
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
};

export default ProductCard;
