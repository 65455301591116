import { useState } from 'react';

import { Grid, UICard } from 'site-react/components/page';
import { Heading } from 'site-react/components/typography';
import { ImgixImage, VerticalSpacing } from 'site-react/components/utility';

import styles from './SearchHero.module.css';
import SearchHeroForm from './SearchHeroForm';
import UspCards from './UspCards';

const SearchHero = () => {
  // the following line stops the linter from complaining about the order of these keys
  // the order of these keys is important for the order of the radio buttons
  /* eslint-disable sort-keys */
  const workspaceOfferings = {
    hq: 'Offices',
    'meeting-rooms': 'Meeting rooms',
    coworking: 'Coworking desks',
    'private-day-offices': 'Day offices',
  };
  /* eslint-enable sort-keys */

  const [uspSet, setUspSet] = useState('hq');

  return (
    <div data-testid="SearchHero">
      <Grid breakpoint="xl">
        <Grid.Item colSpan="7">
          <div className={styles['SearchHero-heading']}>
            <Heading level="1" type="hero1">
              Find amazing workspaces for your team to thrive
            </Heading>
          </div>
          <div className={styles['SearchHero-headingMobile']}>
            <Heading isCentered level="1" type="title1">
              Find amazing workspaces for your team to thrive
            </Heading>
          </div>
        </Grid.Item>
        <Grid.Item colSpan="7">
          <UICard
            boxShadow="resting"
            style={{
              padding: {
                default: `var(--space-sm)`,
                lg: `var(--space-xl)`,
              },
            }}
          >
            <SearchHeroForm
              onProductSelected={setUspSet}
              workspaceOfferings={workspaceOfferings}
            />
          </UICard>
        </Grid.Item>
        <Grid.Item colSpan="7">
          <UspCards uspSet={uspSet} />
        </Grid.Item>
        <Grid.Item colSpan="1" colStart="8" rowSpan="3" rowStart="1">
          <div className={styles['SearchHero-imagesGrid']}>
            <div className={styles['SearchHero-imagesColumnLeft']}>
              <ImgixImage
                alt="Woman in office space looking out the window"
                height={244}
                imgixOptions={{ fit: 'crop', h: 244, w: 216 }}
                src="/site/homepage/searchhero-thehickman-office-space.jpg"
                style={{
                  borderRadius: `var(--space-sm)`,
                  boxShadow: `var(--shadow-resting)`,
                }}
                width={216}
              />
              <VerticalSpacing size="sm" />
              <ImgixImage
                alt="A team working in a meeting room with natural light and foliage"
                height={244}
                imgixOptions={{ fit: 'crop', h: 244, w: 216 }}
                src="/site/homepage/searchhero-fora-meeting-room.jpg"
                style={{
                  borderRadius: `var(--space-sm)`,
                  boxShadow: `var(--shadow-resting)`,
                }}
                width={216}
              />
            </div>
            <div className={styles['SearchHero-imagesColumnRight']}>
              <ImgixImage
                alt="A team hybrid working in a meeting room with a large screen"
                height={244}
                imgixOptions={{ fit: 'crop', h: 244, w: 216 }}
                src="/site/homepage/searchhero-hubble-team-meeting-room.jpg"
                style={{
                  borderRadius: `var(--space-sm)`,
                  boxShadow: `var(--shadow-resting)`,
                }}
                width={216}
              />
              <VerticalSpacing size="sm" />
              <ImgixImage
                alt="An office space with desks, chairs and monitors and view of Tower Bridge"
                height={244}
                imgixOptions={{ fit: 'crop', h: 244, w: 216 }}
                src="/site/homepage/searchhero-office-desks.jpg"
                style={{
                  borderRadius: `var(--space-sm)`,
                  boxShadow: `var(--shadow-resting)`,
                }}
                width={216}
              />
            </div>
          </div>
        </Grid.Item>
      </Grid>
    </div>
  );
};

export default SearchHero;
