import Image from 'next/image';
import React from 'react';

import { LinkAsButton } from 'site-react/components/navigation';
import { Grid, Section } from 'site-react/components/page';
import {
  Heading,
  ListItemWithIcon,
  Paragraph,
} from 'site-react/components/typography';
import { UnstyledList, VerticalSpacing } from 'site-react/components/utility';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';

import ProductFeature from './../ProductFeature/ProductFeature';
import styles from './OnDemandForTeam.module.css';

const OnDemandForTeam = () => {
  return (
    <Section verticalPadding="xxxl">
      <ProductFeature
        flip
        image={
          <Image
            alt="illustration of an on-demand office"
            height={570}
            loader={imgixLoader}
            src={getImgixPathWithParameters(
              '/site/homepage/on-demand-for-team/on-demand-membership-2.png',
              {},
            )}
            style={{
              height: 'auto',
              objectFit: 'contain',
              width: '100%',
            }}
            width={540}
          />
        }
      >
        <h3 className="u-typographyLabel1">Hubble On&#8209;Demand</h3>
        <Heading type="title1">In-person working made easy</Heading>
        <VerticalSpacing size="md" />
        <Paragraph isMarginless type="content2">
          For whenever remote teams need to meet in real life, there’s Hubble
          on-demand.
        </Paragraph>
        <UnstyledList>
          <ListItemWithIcon
            contentType="content2"
            iconName="check_circle"
            text={
              <div className={styles['OnDemandForTeam-pitchItem']}>
                <b>One destination</b> for teams to easily book space to work
                in&#8209;person
              </div>
            }
          />
          <ListItemWithIcon
            contentType="content2"
            iconName="check_circle"
            text={
              <div className={styles['OnDemandForTeam-pitchItem']}>
                <b>1000s of workspaces around the UK and beyond</b> - from day
                offices to meeting rooms to coworking access
              </div>
            }
          />
          <ListItemWithIcon
            contentType="content2"
            iconName="check_circle"
            text={
              <div className={styles['OnDemandForTeam-pitchItem']}>
                <b>Up to 30% discount</b> on workspace booking, and only pay for
                the space you use
              </div>
            }
          />
        </UnstyledList>
        <Grid>
          <Grid.Item colSpan="6">
            <LinkAsButton
              analyticsMetadata={{
                data_analytics_id:
                  'home-page-get-your-team-started-cta-on-demand',
              }}
              href={{
                pathname: '/pass',
                query: {
                  type: 'workspace-options',
                  utm_campaign: 'pass',
                  utm_medium: 'website',
                  utm_source: 'homepagepass',
                },
              }}
              isBlock
              name="LEARN MORE"
              styleAtSmall="auto"
            >
              Learn more
            </LinkAsButton>
          </Grid.Item>
        </Grid>
        <VerticalSpacing size="xxl" />
        <Grid breakpoint="xs">
          <Grid.Item colSpan="3">
            <Image
              alt="portrait of rachel carrel"
              height={24}
              loader={imgixLoader}
              src={getImgixPathWithParameters(
                '/site/homepage/on-demand-for-team/rachel-carrel.svg',
                {},
              )}
              style={{
                height: 'auto',
                objectFit: 'contain',
                width: '100%',
              }}
              width={30}
            />
          </Grid.Item>
          <Grid.Item align="start" colSpan="9" colStart="4">
            <div className={styles['OnDemandForTeam-quote']}>
              <Paragraph type="content3">
                We went from having a huge, expensive office to a much more
                flexible working arrangement, with a small central office and
                on&#8209;demand access to lots of great workspaces
              </Paragraph>
              <div className={styles['OnDemandForTeam-quoteCredit']}>
                RACHEL CARRELL
              </div>
              <div>Founder & CEO, Koru Kids</div>
            </div>
          </Grid.Item>
        </Grid>
      </ProductFeature>
      <VerticalSpacing size="lg" />
      <Grid>
        <Grid.Item colSpan="4" justify="center">
          <Heading isCentered level="1" type="hero2">
            450+ <br />{' '}
            <span className={styles['OnDemandForTeam-statsContext']}>
              spaces in London
            </span>
          </Heading>
        </Grid.Item>
        <Grid.Item colSpan="4" justify="center">
          <Heading isCentered level="1" type="hero2">
            750+ <br />{' '}
            <span className={styles['OnDemandForTeam-statsContext']}>
              in the UK
            </span>
          </Heading>
        </Grid.Item>
        <Grid.Item colSpan="4" justify="center">
          <Heading isCentered level="1" type="hero2">
            1250+ <br />{' '}
            <span className={styles['OnDemandForTeam-statsContext']}>
              Worldwide
            </span>
          </Heading>
        </Grid.Item>
      </Grid>
    </Section>
  );
};

export default OnDemandForTeam;
