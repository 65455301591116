import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Home from 'site-react/features/Home';
import Analytics from 'site-react/helpers/Analytics';

import meta from '../content/meta/home';
import PageLayout from '../layouts/PageLayout';

const HomePage = ({ cohort, utmParams }) => {
  useEffect(() => {
    Analytics.setPageProperties({
      page_category: 'static',
      page_type: 'home',
    });

    Analytics.pageTrack('Home');
  }, []);

  return (
    <PageLayout meta={meta}>
      <Home cohort={cohort} utmParams={utmParams} />
    </PageLayout>
  );
};

export const getServerSideProps = async ({ query }) => {
  const utmParams = new URLSearchParams();

  for (const [key, value] of Object.entries(query)) {
    if (key.startsWith('utm_')) {
      utmParams.set(key, encodeURIComponent(value));
    }
  }

  return {
    props: {
      cohort: 'control' /* We'll set this dynamically later */,
      utmParams: utmParams.toString(),
    },
  };
};

HomePage.propTypes = {
  cohort: PropTypes.oneOf(['control', 'variant']).isRequired,

  utmParams: PropTypes.string,
};

export default HomePage;
